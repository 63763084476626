<template>
  <PatologiesPage
    title="Leiomioma"
    subtitle1="I leiomiomi sono tumori benigni che originano dalle cellule della muscolatura liscia del tratto esofago-gastro-intestinale ma possono svilupparsi comunemente anche nell’utero.

I leiomiomi esofagei sono fra i più comuni tumori benigni dell’esofago. Spesso decorrono in forme subcliniche senza mai essere notati e diagnosticati. I leiomiomi gastrici sono ancora più rari di quelli esofagei. Quando presenti i sintomi variano in base alla sede di insorgenza. I più comuni sono una sensazione di difficoltà al transito del bolo alimentare deglutito (disfagia), il dolore e molto più raramente nausea, vomito, senso di sazietà precoce e perdita di peso."
    subtitle2="La diagnosi si avvale dei principali strumenti della diagnostica per immagini, come  la TAC, l’endoscopia e l’ecoendoscopia. Nel sospetto di un leiomioma sarebbe opportuno non effettuare la biopsia poiché tale procedura rende un po’ più difficoltosa la fase di asportazione chirurgica."
    subtitle3="L’unica opzione terapeutica è quella chirurgica. Tuttavia, in considerazione della natura benigna della malattia, non è sempre necessario programmarne l’asportazione. È possibile ricorrere ad un approccio osservazionale che consente di tenere sotto controllo eventuali variazioni cliniche o strumentali che possono svilupparsi nel tempo e che potrebbero portare solo successivamente ad un’indicazione chirurgica.

Nella maggior parte dei casi è possibile una semplice escissione locale risparmiando al paziente una vera e propria resezione d’organo. Quest’ultima viene riservata ai casi più avanzati in cui l’estensione del leiomioma è tale da non consentire semplici asportazioni locali.

La degenza è di pochi giorni (2-3) ed al paziente viene consentita una ripresa dell’alimentazione con dieta morbida fina dalle prime giornate post-operatorie."
    cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
    cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
    cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
    cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
  />
</template>

<script>
import PatologiesPage from "../components/PatologiesPage";

export default {
  name: "Leiomioma",
  components: {
    PatologiesPage,
  },
};
</script>
